import React from 'react';
import './Navbar.css'

function NavBar() {
  return (
    <nav  className='nav_bar'>
      <h1 className='nav_text' >Central Manufacturing Technology Institute</h1>
    </nav>
  );
}

export default NavBar;
